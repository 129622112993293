import { CloudinaryAsset, isCloudinaryAsset } from '../cloudinary/Types'
import {
  ArrayOneOrMore,
  ReqImage,
  isTypedArrayOneOrMore,
  isReqImage,
} from 'types/util'
import { getHeroEyebrow } from '../advisors/Utils'
import Image from 'next/legacy/image'
import { AdvisorVariants } from '../advisors/Types'
import { VideoManager, SimpleVideo } from '../cloudinary/VideoPlayer'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/advisor-reviews.module.css'
import { Modal } from '../common/Modal'
import { CloudinaryAdvancedImage, resizeCldImg } from '../cloudinary/Cloudinary'
import { useRef, useState } from 'react'
import { Cloudinary } from '@cloudinary/url-gen'
import { CloudinaryPriorityImage } from '../ui/DynamicallySourcedImage'

interface ReqComponentAdvisorReviews {
  advisorReviewsCollection: {
    items: ArrayOneOrMore<ReqAdvisorReviews>
  }
}

function isReqComponentAdvisorReviews(
  obj: any
): obj is ReqComponentAdvisorReviews {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.advisorReviewsCollection === 'object' &&
    obj.advisorReviewsCollection !== null &&
    isTypedArrayOneOrMore<ReqAdvisorReviews>(
      obj.advisorReviewsCollection.items,
      isReqAdvisorReviews
    )
  )
}

interface ReqAdvisorReviews {
  advisor: ReqAdvisorReviewsAdvisor
  mediaAsset: ArrayOneOrMore<CloudinaryAsset>
  mobilePreviewImage: ArrayOneOrMore<CloudinaryAsset>
  mobileMediaAsset: ArrayOneOrMore<CloudinaryAsset>
  review: string
  eyebrowText: string
}

function isReqAdvisorReviews(obj: any): obj is ReqAdvisorReviews {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    isReqAdvisorReviewsAdvisor(obj.advisor) &&
    isTypedArrayOneOrMore<CloudinaryAsset>(obj.mediaAsset, isCloudinaryAsset) &&
    isTypedArrayOneOrMore<CloudinaryAsset>(
      obj.mobileMediaAsset,
      isCloudinaryAsset
    ) &&
    isTypedArrayOneOrMore<CloudinaryAsset>(
      obj.mobilePreviewImage,
      isCloudinaryAsset
    ) &&
    typeof obj.review === 'string' &&
    typeof obj.eyebrowText === 'string'
  )
}

interface ReqAdvisorReviewsAdvisor {
  title: string
  slug: string
  firstName: string
  lastName: string
  shortDescription: string | null
  image: ReqImage
  cloudinaryImage: null | CloudinaryAsset[]
  advisorVariant: AdvisorVariants
}

function isReqAdvisorReviewsAdvisor(obj: any): obj is ReqAdvisorReviewsAdvisor {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.title === 'string' &&
    typeof obj.slug === 'string' &&
    typeof obj.firstName === 'string' &&
    typeof obj.lastName === 'string' &&
    (typeof obj.shortDescription === 'string' ||
      obj.shortDescription === null) &&
    (isReqImage(obj.cloudinaryImage[0]) || isReqImage(obj.image)) &&
    Object.values(AdvisorVariants).includes(obj.advisorVariant)
  )
}

const AdvisorReviews = (props) => {
  const videoManager = new VideoManager()
  const pauseAllVideos = () => {
    videoManager.pause()
  }

  const mobileVideoRef = useRef<HTMLVideoElement | null>(null)
  videoManager.register(mobileVideoRef)
  const [modalIndex, setModalIndex] = useState(0)
  const [visible, setVisible] = useState(false)
  const openModal = () => setVisible(true)
  const closeModal = () => {
    pauseAllVideos()
    setVisible(false)
  }
  if (isReqComponentAdvisorReviews(props)) {
    const advisorReviews = props.advisorReviewsCollection.items
    return (
      <AdvisorReviewsDisplay
        mobileVideoRef={mobileVideoRef}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
        openModal={openModal}
        reviews={advisorReviews}
        videoManager={videoManager}
        pauseAllVideos={pauseAllVideos}
        visible={visible}
        closeModal={closeModal}
      />
    )
  } else {
    return null
  }
}

const AdvisorReviewsDisplay = ({
  reviews,
  videoManager,
  pauseAllVideos,
  modalIndex,
  setModalIndex,
  openModal,
  closeModal,
  visible,
  mobileVideoRef,
}: {
  mobileVideoRef: React.MutableRefObject<HTMLVideoElement | null>
  reviews: ArrayOneOrMore<ReqAdvisorReviews>
  videoManager: VideoManager
  pauseAllVideos?: () => void
  modalIndex: number
  setModalIndex: (n: number) => void
  openModal: () => void
  visible: boolean
  closeModal: () => void
}) => (
  <>
    <Modal visible={visible} onClose={closeModal}>
      <div className="grid h-screen place-content-center">
        {/*Captions hard edited into video file*/}
        {/*Use simple video as Cld video player will not take on mobile aspect ratio*/}
        {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
        <SimpleVideo
          videoRef={mobileVideoRef}
          src={reviews[modalIndex].mobileMediaAsset[0].secure_url}
          autoPlay={visible}
          controls={true}
          modalOpen={visible}
        />
      </div>
    </Modal>

    <section className="py-6">
      <ForaSwiper
        className={styles.foraSwiper}
        params={{
          on: {
            slideChange: pauseAllVideos,
          },
          navigation: true,
          pagination: true,
          keyboard: true,
          slidesPerView: 1.2,
          spaceBetween: 16,
          centeredSlides: false,
          breakpoints: {
            768: {
              slidesPerView: 1.5,
              centeredSlides: true,
            },
            1024: {
              slidesPerView: 1,
              centeredSlides: true,
              spaceBetween: 8,
            },
          },
        }}
      >
        {reviews.map((review, i) => (
          <div key={i} className="">
            <AdvisorReview
              review={review}
              videoManager={videoManager}
              index={i}
              openModal={openModal}
              setModalIndex={setModalIndex}
            />
          </div>
        ))}
      </ForaSwiper>
    </section>
  </>
)

const AdvisorReview = ({
  review,
  videoManager,
  index,
  openModal,
  setModalIndex,
}: {
  review: ReqAdvisorReviews
  videoManager: VideoManager
  index: number
  openModal: () => void
  setModalIndex: (n: number) => void
}) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'foratravelweb',
    },
  })
  const posterImg = cld.image(review.mobilePreviewImage[0].public_id)
  const poster = posterImg
    .resize(
      resizeCldImg({
        width: review.mediaAsset[0].width,
        height: review.mediaAsset[0].height,
        method: 'fill',
        gravity: 'face',
      })
    )
    .toURL()
  const desktopVideoRef = useRef<HTMLVideoElement | null>(null)
  const isImage = review.mediaAsset[0].resource_type === 'image'
  videoManager.register(desktopVideoRef)

  return (
    <div className="w-full lg:px-10">
      <div className="w-full bg-darkSand">
        <div data-name="mobile-video" className="block lg:hidden">
          <div
            onClick={() => {
              setModalIndex(index)
              !isImage && openModal()
            }}
            onKeyDown={() => {
              setModalIndex(index)
              !isImage && openModal()
            }}
            role="button"
            tabIndex={0}
            className="relative w-full max-h-[190px] overflow-hidden"
          >
            <CloudinaryAdvancedImage
              public_id={review.mobilePreviewImage[0].public_id}
              width={666}
              className="w-full"
            />
            {!isImage && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Image
                  src="/images/PlayButton.svg"
                  alt="Play"
                  width={48}
                  height={48}
                />
              </div>
            )}
          </div>
        </div>
        <div className="block h-full">
          <div className="grid h-full grid-cols-1 lg:grid-cols-2">
            <div
              data-name="desktop-video"
              className="hidden col-span-1 my-auto lg:block"
            >
              {isImage ? (
                <div className="relative inset-0 flex items-center justify-center">
                  <Image
                    src={poster}
                    alt="Play"
                    width={review.mediaAsset[0].width}
                    height={review.mediaAsset[0].height}
                  />
                </div>
              ) : (
                <SimpleVideo
                  customClass="advisor-review"
                  src={review.mediaAsset[0].secure_url}
                  autoPlay={false}
                  controls={true}
                  poster={poster}
                  videoRef={desktopVideoRef}
                />
              )}
            </div>
            <div
              data-name="review-text-content"
              className="flex flex-col justify-between md:grid grid-cols-1 col-span-1 px-6 md:px-4 py-6 lg:px-12 md:place-content-center lg:min-h-[0px] md:min-h-[738px] min-h-[315px]"
            >
              <div>
                <div
                  data-name="constant-subhead"
                  className="col-span-1 mb-2 md:mb-4 fora-text-h8 md:fora-text-h7"
                >
                  {review.eyebrowText}
                </div>
                <div
                  data-name="review-text"
                  className="fora-text-callout-4 md:mb-12 md:min-h-auto"
                >{`"${review.review}"`}</div>
              </div>
              <div>
                <a
                  href={`/advisor/${review.advisor.slug}`}
                  className="block mb-2"
                >
                  <AdvisorProfile advisor={review.advisor} />
                </a>
                {review.advisor.shortDescription && (
                  <div
                    className="fora-text-body-3"
                    data-name="advisor-short-description"
                  >
                    {review.advisor.shortDescription}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const AdvisorProfile = ({
  advisor,
}: {
  advisor: ReqAdvisorReviewsAdvisor
}) => (
  <div className="flex items-center">
    <div className="w-10 mr-2">
      <CloudinaryPriorityImage
        className="rounded-full"
        contentfulImage={advisor?.image || null}
        cloudinaryImage={advisor?.cloudinaryImage}
        alt={advisor?.image?.description || `Advisor - ${advisor.title}`}
        width={640}
        height={640}
        layout="responsive"
        quality={90}
        fit="fill"
        format="webp"
      />
    </div>
    <div className="">
      <h3 className="fora-text-body-italic-3">
        {getHeroEyebrow(advisor.advisorVariant || AdvisorVariants.PERSON)}
      </h3>
      <h2 className="fora-text-h7">{advisor.title}</h2>
    </div>
  </div>
)

export default AdvisorReviews
